/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/style.scss"
import "./src/styles/style.css"
import './node_modules/react-modal-video/scss/modal-video.scss';
import './src/styles/modal.css'
                                                                     