module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Buat Dia Terus Kembali","short_name":"BDTK","start_url":"/","background_color":"#20232a","theme_color":"#20232a","display":"standalone","icon":"./src/images/gatsby-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"9b2e4dd82ea29603566564dee61b14d5"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-75736354-18","head":false,"anonymize":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-W7829PK","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"gtmAuth":"5WV8PMyqTlVAldVD07LTbQ","gtmPreview":"env-1","routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
